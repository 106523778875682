@import "~react-image-gallery/styles/css/image-gallery.css";
@media screen and (max-width: 400px) {
  #for-adoption
  #about,
  #help-us,
  #charities,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
